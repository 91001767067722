var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-stack h-stretch gap-5"},[_c('div',{staticClass:"pane-right gap-5"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.filter=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((this.categories),function(category){return _c('option',{key:category},[_vm._v(" "+_vm._s(category)+" ")])}),0),(_vm.$store.state.accessLevel > 8)?_c('button',{staticClass:"add",on:{"click":function($event){return _vm.$router.push('/users/freelancers/add')}}},[_vm._v(" New ")]):_vm._e()]),(_vm.$store.state.accessLevel > 5)?_c('Table',{attrs:{"items":_vm.freelancers,"defaultColumn":0,"css":"200px 100px 100px 1fr 80px 40px 1fr","columns":[
      {
        name: 'Name',
        sort: 'alphabet',
        path: ['fullName'],
      },
      {
        name: 'Professions',
        sort: 'alphabet',
        path: ['categories'],
        formatType: 'array',
        format: ['name'],
      },
      {
        name: 'Phone',
        sort: 'numeric',
        path: ['phoneNumber'],
        formatType: 'phone',
      },
      {
        name: 'Email',
        sort: 'alphabet',
        path: ['email'],
      },
      {
        name: 'Daily Fee',
        sort: 'numeric',
        path: ['categories'],
        formatType: 'array',
        format: ['dailyFee'],
      },
      {
        name: 'Rating',
        sort: 'numeric',
        path: ['rating'],
      },
      {
        name: 'Desciption',
        sort: 'alphabet',
        path: ['description'],
      } ]},on:{"rowSelected":function($event){return _vm.$router.push('/users/detail/' + $event._id)}}}):_vm._e(),(_vm.$store.state.accessLevel <= 5)?_c('Table',{attrs:{"items":_vm.freelancers,"defaultColumn":0,"css":"1fr 160px 100px 1fr","columns":[
      {
        name: 'Name',
        sort: 'alphabet',
        path: ['fullName'],
      },
      {
        name: 'Professions',
        sort: 'alphabet',
        path: ['categories'],
        formatType: 'array',
        format: ['name'],
      },
      {
        name: 'Phone',
        sort: 'numeric',
        path: ['phoneNumber'],
      },
      {
        name: 'Email',
        sort: 'alphabet',
        path: ['email'],
      } ]},on:{"rowSelected":function($event){return _vm.$router.push('/users/detail/' + $event._id)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }