<template>
  <div class="v-stack h-stretch gap-5">
    <div class="pane-right gap-5">
      <select v-model="filter">
        <option v-for="category in this.categories" :key="category">
          {{ category }}
        </option>
      </select>
      <button
        v-if="$store.state.accessLevel > 8"
        @click="$router.push('/users/freelancers/add')"
        class="add"
      >
        New
      </button>
    </div>
    <Table
      v-if="$store.state.accessLevel > 5"
      :items="freelancers"
      :defaultColumn="0"
      css="200px 100px 100px 1fr 80px 40px 1fr"
      v-on:rowSelected="$router.push('/users/detail/' + $event._id)"
      :columns="[
        {
          name: 'Name',
          sort: 'alphabet',
          path: ['fullName'],
        },
        {
          name: 'Professions',
          sort: 'alphabet',
          path: ['categories'],
          formatType: 'array',
          format: ['name'],
        },
        {
          name: 'Phone',
          sort: 'numeric',
          path: ['phoneNumber'],
          formatType: 'phone',
        },
        {
          name: 'Email',
          sort: 'alphabet',
          path: ['email'],
        },
        {
          name: 'Daily Fee',
          sort: 'numeric',
          path: ['categories'],
          formatType: 'array',
          format: ['dailyFee'],
        },
        {
          name: 'Rating',
          sort: 'numeric',
          path: ['rating'],
        },
        {
          name: 'Desciption',
          sort: 'alphabet',
          path: ['description'],
        },
      ]"
    ></Table>
    <Table
      v-if="$store.state.accessLevel <= 5"
      :items="freelancers"
      :defaultColumn="0"
      css="1fr 160px 100px 1fr"
      v-on:rowSelected="$router.push('/users/detail/' + $event._id)"
      :columns="[
        {
          name: 'Name',
          sort: 'alphabet',
          path: ['fullName'],
        },
        {
          name: 'Professions',
          sort: 'alphabet',
          path: ['categories'],
          formatType: 'array',
          format: ['name'],
        },
        {
          name: 'Phone',
          sort: 'numeric',
          path: ['phoneNumber'],
        },
        {
          name: 'Email',
          sort: 'alphabet',
          path: ['email'],
        },
      ]"
    ></Table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";

export default {
  data() {
    return {
      users: [],
      filter: "All",
      categories: ["All"],
    };
  },
  components: {
    Table,
  },
  computed: {
    freelancers() {
      const array = this.users.filter((user) => user.accessLevel == 0);

      if (this.filter == "All") {
        return array;
      }

      return array.filter((user) =>
        user.categories.map((item) => item.name).includes(this.filter)
      );
    },
  },
  methods: {
    ...mapActions(["getUsers"]),
    refresh() {
      this.getUsers()
        .then((users) => {
          this.users = users;

          let categories = [];

          for (const user of users) {
            const array = user.categories.map((item) => item.name);
            categories = categories.concat(array);
          }

          categories = Array.from(new Set(categories));

          this.categories = this.categories.concat(categories).sort((a, b) => {
            return a.localeCompare(b, "cs");
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
